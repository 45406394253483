


@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap');

.alert {
    padding-right: 0px;
    color: #FFF;
}

html[data-theme="dark"] .dropdown-content label:hover {
    background : var(--fallback-b2,oklch(var(--b2)/var(--tw-bg-opacity)));
}
html[data-theme="dark"] .alert{
    color: #191e24;
}
html[data-theme="dark"] .title-lang{
    background: #1d232a;
}

html[data-theme="dark"] .ql-snow .ql-stroke {
    stroke: #FFF;
}
html[data-theme="dark"] .ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill{
    fill:#FFF;
}

html[data-theme="dark"] .toggle:checked, .toggle[checked="true"], .toggle[aria-checked="true"] {
    color : oklch(var(--s));
}

html[data-theme="dark"] .dealer-row {
    border-top: 12px solid #191e24;
}


html[data-theme="dark"] .ql-toolbar.ql-snow, html[data-theme="dark"] .ql-container.ql-snow, html[data-theme="dark"] .title-lang,
html[data-theme="dark"] .title-input input{
    border: 1px solid var(--fallback-bc,oklch(var(--bc)/0.2));
}
.logoFB{
    font-family: "Unbounded", sans-serif;
    font-size: 32px;
}
h1, .stat-value{
    font-family: "Unbounded", sans-serif;
}
 .dealer-row {
    border-top: 12px solid #f2f2f2;
}


    /*---------------MODALS----------------*/
#modal-notifications{
    z-index: 999998;
}



.modal-communication .modal-box, .modal-changelog .modal-box {
    width: 100%;
    max-width: 640px;
    border-radius: 0px;
    background : #FFF;
}
.modal-header {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    align-items: center;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    padding: 32px 32px 48px 32px;
    display: block;
    color: #FFF;
}
.modal-changelog .modal-body, .modal-communication .modal-body{
    padding: 32px;
    background: #FFF;
}

.modal-changelog .preview-content, .modal-communication  .preview-content {
    display: flex;
    position: relative;
    border: 1px solid #D8DADD;
    align-items: flex-start;
    height: auto;
    padding: 7px 20px 20px 20px;
    justify-content: flex-start;
    flex-direction: column;
}
.modal-changelog  .modal-header .badge,
.modal-communication  .modal-header .badge
{
    padding: 3px 7px;
    border-radius: 6px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    z-index: 1;
    position: relative;
    background: none;
    border: none;
}
.modal-changelog  .modal-header h5,
.modal-communication   .modal-header h5
{
    color: var(--white);
    font-family: 'Expressway', Arial, Helvetica, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    padding-right: 110px;
    line-height: 56px;
    z-index: 2;
    position: relative;
}

.modal-changelog  .modal-header .overlay ,
.modal-communication  .modal-header .overlay {
    background: linear-gradient(180deg, #051525 0%,#282E3F 100%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 0;
}

.modal-communication  .overlay-europe,
.modal-changelog .overlay-europe {
    background: url(https://fastback.dev/admin/templates/default/images/img-hero-red.png);
    background-size: 300px;
    background-repeat: no-repeat;
    background-position: bottom right;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 1;
    opacity: 0.7;
}



.modal-changelog  .changelogs h1 {
    padding: 0px 5px;
    border-radius: 6px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    display: inline-block;
    color: #FFFFFF!important;
    margin-left: -30px;
    margin-top: 0px;
    background : #1765AD;
}
.modal-changelog .changelogs h1:last-of-type {
    background : green;
}

.modal-changelog .changelogs ul li
{
    color: #525765!important;
    font-family: 'Expressway', Arial, Helvetica, sans-serif;
    font-size: 12px!important;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding-left: 15px;
    text-decoration: none;
    position: relative;
}

.changelogs  li::before {
    content: '\2022';
    color: rgb(82 87 101);
    font-size: 32px;
    line-height: 0px;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 46%;
}

.changelogs  p {
    color: #525765!important;
    font-family: 'Expressway', Arial, Helvetica, sans-serif;
    font-size: 12px!important;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-decoration: none;
}
.ql-toolbar {
    display: flex;
    gap: 10px;
}
.ql-toolbar .custom-btn-img{
    width:auto!important;
    display: flex!important;
    align-content: center!important;
}
.ql-toolbar .toolbar-divider{
    height: 24px;
    width: 1px;
    background: #ccc;
    margin: 0px 10px;
}

/*.ql-toolbar button {
    background: #fff!important;
    border: 1px solid #ccc!important;
    cursor: pointer!important;
    width: auto!important;
    border-radius: 4px!important;
    display: flex!important;
    align-items: center!important;
}

.ql-snow .ql-picker{
    border: 1px solid #cccccc;
    border-radius: 4px;
}
.ql-toolbar button:hover {
    background: #f0f0f0;
}*/

 .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    padding : 0px 32px 32px 32px;
}
.modal-footer .btn {
    font-family: 'Expressway', Arial, Helvetica, sans-serif;
    margin: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 12px;
    font-size: 12px;
    background: #D32029;
    color: #FFF;
    border-radius: 0px;
    border: 1px;
}


/*COMMUNICATION MODAL*/

.modal-communication  h1,.modal-communication   h2, .modal-communication   h3, .modal-communication    h4, .modal-communication   h5, .modal-communication   h6 {
    font-family: 'Expressway', Arial, Helvetica, sans-serif;
    color:#272d3e;
    font-weight: bold;
    font-size: 26px;
}



.modal-communication  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.modal-communication  p {
    font-size: 14px;
}
.modal-communication  ul li {
    position: relative;
    padding-left: 3em;
    font-size: 16px!important;
    line-height: 2;
    color:#272d3e;
    font-family: 'Expressway', Arial, Helvetica, sans-serif;
    font-weight: bold;

}

.modal-communication  ul li::before {
    content: '•';
    margin-right: 9px;
    margin-left: -20px;
    font-size: 24px;
    line-height: 0;
    color: #272d3e;
    position: absolute;
    left: 50px;
    top: 16px;
}

.modal-communication ::marker {
    font-size: 0px!important;
}


.modal-communication   p.ql-indent-1{
    font-family: 'Expressway', Arial, Helvetica, sans-serif;
    color:#272d3e;
    padding-left: 48px;
}




.title-lang {
    position: absolute;
    left: -194px;
    top: 150px;
    transform: rotate(-90deg);
    width: 344px;
    background: #FFF;
    z-index: 0;
    border-radius: 10px 10px 0px 0px;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 10px;
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    border-top: 1px solid #cccccc;
}

.mockup-code.custom {
    height: 300px;
    overflow: auto;
}
.mockup-code.custom pre {
    padding-left: 30px;
}

.stats {
    overflow:hidden;
}

.dropdown-content label {
    padding : 5px;
}
.dropdown-content label:hover {
    background : #f1f1f1;
    border-radius: 10px;
}
.bg-image{
    background-image: url("https://fastback-trade.com/templates/trade/images/img-hero-red.png");
    background-size: contain;
    background-position: bottom right;
    background-repeat: no-repeat;
    opacity: 0.5;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 800px;
    height: 300px;

}

.drawer-side .menu a.active svg{
    color:var(--fallback-p,oklch(var(--p)/var(--tw-text-opacity))) ;
}

.menu li > *:not(ul):not(.menu-title):not(details):active, .menu li > *:not(ul):not(.menu-title):not(details).active, .menu li > details > summary:active{
   color:var(--fallback-bc,oklch(var(--bc)/1));
    background-color:var(--fallback-b2,oklch(var(--b2)/var(--tw-bg-opacity)));
    font-weight:bold;
    border-left: 3px solid var(--fallback-p, oklch(var(--p) / var(--tw-text-opacity)));
    border-radius: 0px 10px 10px 0px;

}


@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateX(0);
    }
    to {
        opacity: 0;
        transform: translateX(-50%);
    }
}

.deleting {
    animation: fadeOut 0.5s forwards;

}
.table :where(.table-pin-rows thead tr){
    z-index: 0;
}

/*.min-h-full{
    min-height: 100%;
}*/
